import FillCaseMetaForm from '../fillMetaForm/FillCaseMetaForm';
import React from 'react';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';

const HuddleFillMeta = (): React.JSX.Element => {
  const { handleProcedureChange, control, formState, allValues, handleMetaDataChange } =
    useFillMetaForm();
  const { caseStore } = useRootStore();
  const handleClose = async (): Promise<void> => {
    caseStore.setIsHuddleDialogOpen(false);
  };

  return (
    <div>
      <FillCaseMetaForm
        handleProcedureChange={handleProcedureChange}
        handleClose={handleClose}
        control={control}
        formState={formState}
        allValues={allValues}
        showSubmit={true}
        handleMetaDataChange={handleMetaDataChange}
      />
    </div>
  );
};

export default observer(HuddleFillMeta);
