import _ from 'lodash';
import { useRef } from 'react';

const useDebounce = (
  func: any,
  wait: number,
  options: _.DebounceSettings
): _.DebouncedFunc<any> => {
  return useRef(_.debounce(func, wait, options)).current;
};

export default useDebounce;
