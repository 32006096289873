import { getAudPrefix, getAudPrefixForEnvVar } from '../../../utils/audPrefix';
import urlParams from '../urlParams';
import { getAud, isStringSet } from '../helper';
import ErrorMonitor from '../errorMonitor/errorMonitor';

const { REACT_APP_DOMAIN, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env;

if (!isStringSet(REACT_APP_AUTH0_DOMAIN)) {
  throw new Error('REACT_APP_AUTH0_DOMAIN is not defined');
}

const clientIdVarName = `REACT_APP_AUTH0_${getAudPrefixForEnvVar()
  .toUpperCase()
  .replace('-', '_')}_CLIENT_ID`;
const depClientId = process.env[`${clientIdVarName}`];

if (!isStringSet(REACT_APP_AUTH0_CLIENT_ID) && !isStringSet(depClientId)) {
  throw new Error(`REACT_APP_AUTH0_CLIENT_ID and ${clientIdVarName} are not defined`);
}

const first = window.location.host.split('.')[0];

const aud = getAud(first);
if (aud === undefined || aud === '') {
  ErrorMonitor.captureMessage('Missing audience');
}

const audPrefix = getAudPrefix();
const overrideConnectionKey = '257d14b2-c83a-41d3-a5ad-449bd85c0904';
const v = `REACT_APP_AUTH0_${audPrefix.toUpperCase().replace('-', '_')}_ALLOWED_CONNECTIONS`;
const allowedConnectionsString =
  localStorage.getItem(overrideConnectionKey) ??
  process.env[v] ??
  'Username-Password-Authentication';
const appDomain = REACT_APP_DOMAIN ?? 'chiefyteam.com';
const allowedConnections = allowedConnectionsString.split(',');
const email = urlParams.get('email');
const loginHint = email === null ? '' : (email as string);
const callbackUrl = `${window.location.protocol}//${window.location.host}/callback/`;
console.log('callbackUrl', callbackUrl);
const AUTH_CONFIG: {
  aud: string;
  audPrefix: string;
  domain: string;
  clientId: string;
  callbackUrl: string;
  allowedConnections: string[];
  connection: string;
  loginHint: string;
} = {
  audPrefix,
  aud: `https://${aud}.${appDomain}/api`,
  domain: REACT_APP_AUTH0_DOMAIN ?? '',
  clientId: depClientId ?? REACT_APP_AUTH0_CLIENT_ID ?? '',
  callbackUrl: `${window.location.protocol}//${window.location.host}/callback/`,
  allowedConnections,
  connection: allowedConnections[0],
  loginHint
  // callbackUrl: REACT_APP_AUTH0_CALLBACK_URL
};
export default AUTH_CONFIG;
