import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import type { ISelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import useProcedureSuggestions from 'app/components/caseViews/hooks/useProcedureSuggestions';
import ProcedureSelect from 'app/components/fields/ProcedureSelect';
import ProcedureChangeConfirmation from '../fields/ProcedureChangeConfirmation';
import { useRootStore } from '../../mobxStore';
import type { Control } from 'react-hook-form/dist/types/form';
import { toast } from 'react-toastify';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

interface IProps {
  allValues: IFormData;
  control: Control<IFormData>;
  handleProcedureChange: (procedure: ISelectOption, approved: boolean) => Promise<boolean>;
}

const ProcedureSelectWithConfirm = (props: IProps): React.JSX.Element => {
  const { handleProcedureChange, allValues, control } = props;
  const { canUpdate, metaDataStore } = useRootStore();
  const [showConfirmationPanel, setShowConfirmationPanel] = React.useState(false);
  const [selectedProcedure, setSelectedProcedure] = React.useState<ISelectOption | null>(null);
  const [procedureToConfirmChange, setProcedureToConfirm] = React.useState<ISelectOption | null>(
    null
  );

  const siteId = allValues[FORM_FIELDS_ENUM.SERVICE]?.value;
  const procedureOptions = metaDataStore.proceduresOptions(siteId);

  useEffect(() => {
    if (selectedProcedure === undefined || selectedProcedure === null) {
      return;
    }

    const handleChange = async (option: ISelectOption | null): Promise<void> => {
      try {
        const success = await handleProcedureChange(option as ISelectOption, false);
        if (!success) {
          setProcedureToConfirm(option);
          setShowConfirmationPanel(true);
        }
      } catch (error) {
        toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
        console.error(error);
        ErrorMonitor.captureException(error as Error);
      }
    };

    void handleChange(selectedProcedure);
  }, [selectedProcedure]);

  const { procSuggestions, loaded } = useProcedureSuggestions({
    procedures: procedureOptions,
    procedureTitle: allValues[FORM_FIELDS_ENUM.PROCEDURE]?.label ?? '',
    title: allValues[FORM_FIELDS_ENUM.TITLE],
    description: allValues[FORM_FIELDS_ENUM.DESCRIPTION],
    siteId: allValues[FORM_FIELDS_ENUM.SERVICE]?.value ?? '',
    attendingId: allValues[FORM_FIELDS_ENUM.SURGEON]?.value ?? ''
  });

  return (
    <>
      <ProcedureSelect
        required={false}
        control={control}
        options={procedureOptions}
        isReadOnly={!canUpdate}
        procSuggestions={procSuggestions}
        allValues={allValues}
        loaded={loaded}
        onChange={(option: ISelectOption | null): void => {
          setSelectedProcedure(option);
        }}
      />
      <ProcedureChangeConfirmation
        isShowPanel={showConfirmationPanel}
        setIsShowPanel={setShowConfirmationPanel}
        onApprove={() => {
          void handleProcedureChange(procedureToConfirmChange as ISelectOption, true);
        }}
      />
    </>
  );
};

export default observer(ProcedureSelectWithConfirm);
