import type { IDynamicField } from '../../mobxStore/types';

export const fieldNeedsAttention = (
  dField: IDynamicField,
  currentUserLastSeen: Date | null
): boolean => {
  if (!dField.createdAt || !currentUserLastSeen) {
    return false;
  }

  const res = new Date(dField.createdAt).getTime() > new Date(currentUserLastSeen).getTime();
  return res;
};
