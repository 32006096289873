import { useController } from 'react-hook-form';
import React, { type MouseEvent, type SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Control } from 'react-hook-form/dist/types/form';
import type {
  ICase,
  ISelectOption,
  ISelectOptionWithCount,
  IUserSelectOption
} from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import clsx from 'clsx';
import Autocomplete from '@mui/material/Autocomplete';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import ProcedureSuggestionButton from './ProcedureSuggestionButton';
// import Paper from '@mui/material/Paper';
import Button from 'app/components/buttons/Button';
import PageIcon from 'app/assets/icons/Page_icon';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import SearchIcon from 'app/assets/icons/Search_icon';
import variables from 'app/utils/variables.module.scss';
import type { FilterOptionsState } from '@mui/base/useAutocomplete/useAutocomplete';
import useProcedureSuggestions from 'app/components/caseViews/hooks/useProcedureSuggestions';
import AmplService from '../../services/amplService/amplService';
import Grow from '@mui/material/Grow';

import { useRootStore } from '../../mobxStore';

interface IProps {
  control: Control<IFormData>;
  options: ISelectOption[];
  isReadOnly?: boolean;
  procSuggestions: ISelectOptionWithCount[];
  allValues?: IFormData;
  required: boolean;
  loaded: boolean;
  onChange: (option: ISelectOption | null) => void;
}

const ProcedureSelect = (props: IProps): React.JSX.Element | null => {
  const { loaded, required, control, options, isReadOnly, procSuggestions, allValues, onChange } =
    props;

  const {
    field: { value, ref, onBlur }
  } = useController({
    rules: { required },
    name: FORM_FIELDS_ENUM.PROCEDURE,
    control
  });
  const isProcSelected = value !== undefined && value !== null;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isOpen, seIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { openedCase } = useRootStore().caseStore;
  const [renderedSuggestionIndex, setRenderedSuggestionIndex] = useState(0);

  const { procSuggestions: procSuggestionsFiltered } = useProcedureSuggestions({
    procedures: options,
    procedureTitle: allValues ? allValues[FORM_FIELDS_ENUM.PROCEDURE]?.label ?? '' : '',
    title: inputValue,
    description: allValues ? allValues[FORM_FIELDS_ENUM.DESCRIPTION] : '',
    siteId: allValues ? allValues[FORM_FIELDS_ENUM.SERVICE]?.value ?? '' : '',
    attendingId: allValues ? allValues[FORM_FIELDS_ENUM.SURGEON]?.value ?? '' : ''
  });

  const filterOptions = (
    options: ISelectOption[],
    state: FilterOptionsState<IUserSelectOption>
  ): ISelectOption[] => {
    const filtered = options.filter(option => {
      const words = state.inputValue.toLowerCase().split(' ');
      const wordsToIgnore = [
        'and',
        'or',
        'the',
        'of',
        'in',
        'a',
        'an',
        'on',
        'at',
        'for',
        'with',
        'to',
        'from',
        'by',
        'nor',
        'but',
        'as'
      ];
      return words.every(w => wordsToIgnore.includes(w) || option.label.toLowerCase().includes(w));
    });

    // show 2 to 6 suggestions, depending on ohw many other results are there
    const nofSuggestions = Math.max(2, 6 - filtered.length);
    const slicedArray = procSuggestionsFiltered.slice(0, nofSuggestions);

    // remove suggestions from filtered results
    const filteredWithoutSuggestions = filtered.filter(opt => {
      return !slicedArray.some(sugg => {
        return opt.value === sugg.value;
      });
    });

    // combine suggestions and filtered results
    const combined = slicedArray.concat(filteredWithoutSuggestions);
    return combined;
  };

  const [isClicked, setIsClicked] = useState(false);

  if (isReadOnly && !isProcSelected) {
    return null;
  }

  const handleClose = async (e: React.SyntheticEvent): Promise<void> => {
    seIsOpen(false);
    setIsInputFocused(false);
  };

  const sendEvent = (event: string, md = {}): void => {
    AmplService.sendCaseEvent(event, openedCase?.basicCase as ICase, {
      ...md,
      [AmplService.ATTRIBUTES.INPUT_VALUE]: inputValue
    });
  };

  const handleClickSuggestion = (e: ISelectOptionWithCount): void => {
    onChange(e);
    setIsClicked(true);
    sendEvent(AmplService.EVENTS.CASE_PROCEDURE_SUGGESTION, {
      [AmplService.ATTRIBUTES.SELECTED_TEMPLATE]: e.label
    });
  };

  const getButtonContent = (proc: ISelectOption | undefined): React.JSX.Element => {
    if (proc === undefined) {
      return (
        <div className="select-field-content">
          <div className="placeholder">{t('selectFromAList')}</div>
        </div>
      );
    }
    return <div className="proc-name">{proc.label}</div>;
  };

  const content = (
    <div className="modal-content">
      <div className="autocomplete-container">
        <Autocomplete
          ref={ref}
          onBlur={onBlur}
          freeSolo
          open
          disablePortal
          autoHighlight={true}
          onChange={(e, selectedValue) => {
            const selected = selectedValue as ISelectOption;
            onChange(selected);
            setIsClicked(true);
            void handleClose(e);
            sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_CHOOSE, {
              [AmplService.ATTRIBUTES.SELECTED_TEMPLATE]: selected.label,
              [AmplService.ATTRIBUTES.IS_FROM_RECOMMENDATIONS]:
                procSuggestionsFiltered.includes(selected)
            });
          }}
          options={options}
          filterOptions={filterOptions}
          inputValue={inputValue}
          onInputChange={(event, value) => {
            /* If we're starting a delete, send an event */
            if (inputValue.length > value.length) {
              if (!isDeleting) {
                setIsDeleting(true);
                sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_DELETE_INPUT);
              }
            } else {
              setIsDeleting(false);
            }

            setInputValue(value);
          }}
          ListboxProps={{
            // set list height to screen height - input container plus 16px list padding
            style: {
              maxHeight: 'calc(100vh - 124px)',
              overflow: 'auto'
            }
          }}
          sx={{
            '& input': {
              width: '100%',
              height: 44,
              backgroundColor: variables.grey2,
              borderRadius: 44,
              border: 'none',
              padding: '0 16px',
              boxSizing: 'border-box',
              fontSize: 14,
              color: variables.black,
              '&::placeholder': {
                color: variables.grey5
              },
              '&.focused': {
                outline: `1px solid ${variables.primaryPurple}`
              },
              '&:focus-visible': {
                outline: `1px solid ${variables.primaryPurple}`
              }
            },
            '& .input-container': {
              position: 'relative',
              padding: '32px 24px',
              display: 'flex',
              height: 108,
              boxSizing: 'border-box',
              borderBottom: '1px solid',
              borderColor: variables.grey2,
              '& .back-button': {
                flexShrink: 0,
                marginRight: '8px',
                '.svg-icon': {
                  height: 14
                }
              },
              '& .clear-button': {
                height: '42px',
                width: '42px',
                backgroundColor: variables.white,
                position: 'absolute',
                right: 25,
                top: 33
              }
            }
          }}
          // PaperComponent={({ children }) => (
          //   <Paper
          //     style={{
          //       width: '100%',
          //       boxShadow: 'none'
          //     }}
          //   >
          //     {children}
          //   </Paper>
          // )}
          groupBy={option =>
            procSuggestionsFiltered.includes(option) ? t('suggetionsForYou') : t('searchResults')
          }
          renderInput={params => {
            return (
              <div ref={params.InputProps.ref} className="input-container">
                <Button
                  classNames="btn circle-button back-button"
                  startIcon={<ArrowLeftIcon stroke={variables.grey6} height={10} />}
                  onClick={async (e: SyntheticEvent<Element, Event>): Promise<void> => {
                    void handleClose(e);
                    sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_BACK);
                  }}
                />
                <input
                  type="text"
                  onClick={() => {
                    setIsInputFocused(true);
                  }}
                  {...params.inputProps}
                  className={clsx('text-input', { focused: isInputFocused })}
                  placeholder={t('searchProcedure').toString()}
                />
                {inputValue !== '' && (
                  <Button
                    classNames="btn circle-button clear-button"
                    startIcon={<CloseIcon height={10} stroke={variables.grey6} />}
                    onClick={async (e: MouseEvent) => {
                      setInputValue('');
                    }}
                  />
                )}
              </div>
            );
          }}
          renderOption={(props, option: ISelectOption) => {
            return (
              <li {...props} className="list-option">
                {option.label}
              </li>
            );
          }}
        />
      </div>
    </div>
  );
  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setIsInputFocused(false);
    }
  }, [isOpen]);

  if (!isProcSelected && (!loaded || isClicked)) {
    return null;
  }

  return (
    <div className="procedure-select">
      {!isProcSelected && <div className="title">{t('selectTemplate')}</div>}
      {procSuggestions.length === 0 || isProcSelected ? (
        <Button
          disabled={isReadOnly}
          classNames={clsx('select-field', { 'read-only': isReadOnly })}
          buttonContent={getButtonContent(value)}
          startIcon={<PageIcon />}
          onClick={async (e: MouseEvent) => {
            AmplService.sendCaseEvent(
              AmplService.EVENTS.CASE_SEARCH_TEMPLATE,
              openedCase?.basicCase as ICase
            );
            seIsOpen(true);
          }}
        />
      ) : (
        <div className="proc-suggestions">
          <div className="search-procedure-button-container">
            <Button
              classNames="search-procedure-button"
              buttonContent={
                <div className="content">
                  <p>{t('searchTemplates')}</p>
                  <div className="icon-container">
                    <SearchIcon stroke={variables.grey6} />
                  </div>
                </div>
              }
              onClick={async (e: MouseEvent) => {
                seIsOpen(true);
                sendEvent(AmplService.EVENTS.CASE_SEARCH_TEMPLATE);
              }}
            />
          </div>
          <div className="suggestions">
            <div className="suggestions-label">{t('topSuggestions')}</div>
            {procSuggestions.slice(0, 5).map((proc, index) => (
              <Grow
                key={proc.value}
                in={renderedSuggestionIndex >= index}
                timeout={800}
                style={{ transformOrigin: '0 0 0' }}
                onEnter={() => {
                  setTimeout(() => {
                    setRenderedSuggestionIndex(index + 1);
                  }, 100);
                }}
              >
                <div className="suggestion-button-container">
                  <ProcedureSuggestionButton
                    key={proc.value}
                    procedure={proc}
                    handleClickSuggestion={handleClickSuggestion}
                  />
                </div>
              </Grow>
            ))}
          </div>
        </div>
      )}
      <ModalScreen
        isOpen={isOpen}
        classNames={'full-height procedure-modal'}
        content={content}
        handleClose={() => {
          seIsOpen(false);
          sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_BACK);
        }}
      />
    </div>
  );
};

export default ProcedureSelect;
