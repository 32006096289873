import React, { useEffect, useState } from 'react';
import { type ICase, TabsEnum } from 'app/mobxStore/types';
import { useTranslation } from 'react-i18next';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import CaseListItemMenu from './caseListItemMenu/CaseListItemMenu';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import CaseStatusChip from 'app/components/caseStatusChip/CaseStatusChip';
import UserAvatarGroup from 'app/components/userAvatarGroup/UserAvatarGroup';
import variables from 'app/utils/variables.module.scss';
import './CaseListItem.scss';

import LightIcon from 'app/assets/icons/Light_icon';
import DragIcon from 'app/assets/icons/Drag_icon';
import SpeechBubbleFullIcon from 'app/assets/icons/SpeechBubbleFull_icon';
import { useRootStore } from 'app/mobxStore';
// import { getIntraopText } from 'utils/form/form';
import { observer } from 'mobx-react-lite';
import { parseUserToSelectOption } from '../../../mobxStore/MetaDataStore';
import CaseListItemCta from './CaseListItemCta';
import useOurSearchParams from '../../caseViews/hooks/useOurSearchParams';
import Lottie from 'lottie-react';
import Confetti from 'app/assets/lotties/confetti-chiefy-theme.json';
import { getIntraopText } from '../../../../utils/form/form';
import AmplService from '../../../services/amplService';

interface IProps {
  kase: ICase;
  isGhost?: boolean;
  isDragging: boolean;
}

const CaseListItem = (props: IProps): React.JSX.Element => {
  const {
    kase: { data: caseData },
    isGhost,
    isDragging
  } = props;

  const { userStore, caseStore } = useRootStore();
  const { t } = useTranslation();
  const { setOurSearchParams } = useOurSearchParams();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: caseData.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const intraopText = getIntraopText(caseData);
  const showIntraop =
    !userStore.loggedInUser.isAttending &&
    !userStore.loggedInUser.isAssistant &&
    intraopText !== '';
  // const showFeedback =
  //   !lite && !userStore.loggedInUser.isAttending && !userStore.loggedInUser.isAssistant;
  // const planFeedback = props.kase.getCurrentUserPlanFeedback();
  const [dateToPostponeCase, setDateToPostponeCase] = useState<Date>();

  const unreadCommentsCount = caseStore.unreadCommentsMap.get(caseData.id) ?? 0;
  const lastSeen = props.kase.getCurrentUserLastSeen();
  const isShowBadge = props.kase.needsAttention(lastSeen) || unreadCommentsCount > 0;

  const onClick = (): void => {
    setOurSearchParams({ caseId: caseData.id, tab: TabsEnum.CASE }, true);
    AmplService.sendCaseEvent(AmplService.EVENTS.HOME_CASE_LIST_ITEM, props.kase);
  };

  const [isShowConfetti, setIsShowConfetti] = useState(false);
  useEffect(() => {
    const caseToShowConfetti = caseStore.recentlyHuddledCase;
    if (caseToShowConfetti && caseToShowConfetti.id === caseData.id) {
      setTimeout(() => {
        setIsShowConfetti(true);
      }, 400);
    }
  }, [caseStore.recentlyHuddledCase]);

  const getBadgeContent = (): React.JSX.Element => {
    if (unreadCommentsCount > 0) {
      return <SpeechBubbleFullIcon fill={variables.white} stroke={variables.white} />;
    }
    return <></>;
  };

  const getPatientInfo = (): React.JSX.Element | null => {
    const initials = props.kase.data.patientInitials;
    const mrn = props.kase.data.mrn;

    if (!initials && !mrn) {
      return null;
    }

    return (
      <div className="patient-info">
        {initials && <div className="initials">{initials}</div>}
        {mrn && <div className="number">{mrn}</div>}
      </div>
    );
  };

  const indexInDayForAttending = caseStore.getCaseIndexInDayForAttending(caseData);

  const badgeContent = getBadgeContent();
  const patientInfo = getPatientInfo();
  return (
    <>
      <div
        className={clsx('case-list-item', { isGhost, isDragging })}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onClick={onClick}
      >
        <div className="drag-handle" {...listeners}>
          <div className="drag-icon-wrapper">
            <DragIcon height={13} />
          </div>
        </div>
        <div className="case-list-item-content">
          <div className="header">
            {caseData.attending !== null && (
              <div className="attending">
                <UserAvatar user={parseUserToSelectOption(caseData.attending)} size={'medium'} />
                <div className="name">
                  {caseData.attending.nickName}
                  {userStore.loggedInUser.data.id === caseData.attending.id && (
                    <div className="same-user">{` (${t('you')})`}</div>
                  )}
                </div>
              </div>
            )}
            <div className="case-info-chip">{`${t('caseNumber')}${indexInDayForAttending}`}</div>
            {props.kase.data.room && <div className="case-info-chip">{props.kase.data.room}</div>}
            <CaseListItemMenu
              case={props.kase}
              dateToPostponeCase={dateToPostponeCase}
              setDateToPostponeCase={setDateToPostponeCase}
            />
          </div>
          <div className="title">{caseData.displayId}</div>
          {patientInfo}
          <div className="middle">
            {showIntraop && (
              <div className="intraop-indicator">
                <LightIcon className="svg-icon" stroke={variables.notification} />
                <p className="text">{intraopText}</p>
              </div>
            )}
            <UserAvatarGroup
              users={caseData.caseFollowers.map(f => parseUserToSelectOption(f.user))}
              maxUsers={5}
              size="medium"
            />
          </div>
          <div className="footer">
            <CaseListItemCta kase={props.kase} />
            <CaseStatusChip case={props.kase} />
          </div>
        </div>
        {isShowBadge && <div className="attention-badge">{badgeContent}</div>}
        {isShowConfetti && (
          <div className="confetti">
            <Lottie
              animationData={Confetti}
              loop={false}
              className="lottie-container"
              onComplete={() => {
                caseStore.setRecentlyHuddledCase(null);
                setIsShowConfetti(false);
                setTimeout(() => {
                  if (caseStore.caseToOpenNext) {
                    setOurSearchParams(
                      { caseId: caseStore.caseToOpenNext.id, tab: TabsEnum.CASE },
                      true
                    );
                    caseStore.setCaseToOpenNext(null);
                  }
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(CaseListItem);
