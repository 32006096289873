import { useRootStore } from 'app/mobxStore';
import { caseStateInBriefAndReview, USER_CASE_ROLES } from 'app/consts';

export const useShowBriefNotReady = (): boolean => {
  const { caseStore, userRoleInCase } = useRootStore();
  if (caseStore.openedCase === null) {
    return false;
  }
  const openedCase = caseStore.openedCase;
  return (
    userRoleInCase === USER_CASE_ROLES.NONE &&
    caseStateInBriefAndReview(openedCase.basicCase.data.state)
  );
};
