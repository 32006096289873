import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import BriefForm from '../briefForm/BriefForm';
import SubmitButton from '../buttons/SubmitButton';
import { t } from 'i18next';
import { useBriefForm } from '../briefForm/useBriefForm';
import CloseButton from 'app/components/buttons/CloseButton';
import DoneIcon from 'app/assets/icons/Done_icon';
import ProcedureSelect from '../fields/ProcedureSelect';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { toast } from 'react-toastify';
import { log } from 'debug';
import AmplService from '../../services/amplService';
import { useRootStore } from '../../mobxStore';
import type { ICase, IFullCase } from '../../mobxStore/types';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

interface IProps {
  handleClose: () => Promise<void>;
  applyPrefs: () => void;
}

const EditTemplateContent = (props: IProps): React.JSX.Element => {
  const { handleClose, applyPrefs } = props;
  const {
    fields,
    control: briefFormControl,
    submitDefault,
    handleSubmit: briefFormHandleSubmit,
    allValues: briefFormAllValues,
    loadTemplate,
    setFocus,
    formState: briefFormState
  } = useBriefForm(true);
  const { openedCase } = useRootStore().caseStore;

  useEffect(() => {
    void loadTemplate();
  }, []);

  const submitBrief = briefFormHandleSubmit(submitDefault);
  const handleSubmit = async (): Promise<void> => {
    try {
      await submitBrief();
      void handleClose();
      AmplService.sendCaseEvent(
        AmplService.EVENTS.EDIT_PREFERENCES_SAVE,
        openedCase?.basicCase as ICase
      );
      applyPrefs();
      setTimeout(() => {
        toast.success(`Huddle updated. We will remember this for next time.`);
      }, 1000);
    } catch (error) {
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
      log(error);
      ErrorMonitor.captureException(error as Error);
    }
  };
  const { control: fillMetaFormControl } = useFillMetaForm();
  const onBackClick = async (): Promise<void> => {
    AmplService.sendEvent(AmplService.EVENTS.EDIT_PREFERENCES_BACK);
    await handleClose();
  };

  const getTitle = (): string => {
    const kase = openedCase as IFullCase;
    const isAttending =
      useRootStore().userStore.loggedInUser.data.id === kase.basicCase.data.attending?.id;
    if (isAttending) {
      return t('editPreferences');
    }

    const attName = kase.basicCase.data.attending?.nickName;
    return t('editAttPreferences', { attending: attName });
  };

  return (
    <div className="case-container">
      <div className="edit-template-header">
        <CloseButton handleClose={onBackClick} />
        <div className="edit-template-title">{getTitle()}</div>
      </div>
      <div className="edit-template-content">
        <ProcedureSelect
          control={fillMetaFormControl}
          options={[]}
          procSuggestions={[]}
          required={false}
          loaded={true}
          onChange={() => {}}
          isReadOnly
        />
        <BriefForm
          formState={briefFormState}
          setFocus={setFocus}
          fields={fields}
          control={briefFormControl}
          allValues={briefFormAllValues}
          submitChange={false}
          showPatientFields={false}
        />
      </div>
      <SubmitButton
        id="edit-template-form-submit-button"
        classNames={'fab right-align submit'}
        submitFunction={handleSubmit}
        content={
          <div className="button-content">
            <DoneIcon stroke={'#fff'} />
            {t('saveAndApply')}
          </div>
        }
      />
    </div>
  );
};

export default observer(EditTemplateContent);
