import React, { useEffect, useState } from 'react';
import type { ICase, IUserSelectOption } from 'app/mobxStore/types';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { setClickedSeenTooltip } from '../../mobxStore/storage';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import TooltipWrapper from 'app/components/tooltipWrapper/TooltipWrapper';
import EyeIcon from 'app/assets/icons/Eye_icon';
import './FollowerChips.scss';
import variables from 'app/utils/variables.module.scss';

interface IProps {
  options: IUserSelectOption[];
  kase: ICase;
  optionToShowTooltip: IUserSelectOption | null;
}

const FollowerChips = (props: IProps): React.JSX.Element => {
  const { options, kase, optionToShowTooltip } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowTooltip(true);
    }, 500);
  }, []);

  const handleCloseTooltip = (): void => {
    setClickedSeenTooltip(true);
    setShowTooltip(false);
  };

  const getSeenIndicator = (istrue: boolean, children: JSX.Element): JSX.Element => {
    if (istrue) {
      return (
        <TooltipWrapper
          name={'seen'}
          open={showTooltip}
          handleCloseTooltip={handleCloseTooltip}
          comp={children}
          tooltipContent={
            <Trans
              i18nKey="seenTooltipText"
              components={{
                eyeIcon: <EyeIcon stroke={variables.grey6} />,
                bold: <span style={{ fontWeight: 600 }} />
              }}
            />
          }
        />
      );
    }
    return children;
  };

  return (
    <>
      {options.map(option => {
        const seen = kase.getUserLastSeen(option.userId);
        return (
          <div key={option.value} className={clsx('follower-chip', { seen })}>
            <UserAvatar user={option} size={'medium'} />
            <div>{option.label}</div>
            {seen &&
              getSeenIndicator(
                optionToShowTooltip?.userId === option.userId,
                <div className="seen-icon-container">
                  <EyeIcon className="seen-icon" />
                </div>
              )}
          </div>
        );
      })}
    </>
  );
};

export default FollowerChips;
