import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';
import React, { useRef, useEffect } from 'react';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import { useTranslation } from 'react-i18next';
import TextInput from '@mui/material/TextField';

interface IProps {
  control: Control<IFormData>;
  isReadOnly?: boolean;
  isFocusOnInit?: boolean;
}

const CaseTitle = (props: IProps): React.JSX.Element => {
  const { control, isReadOnly, isFocusOnInit } = props;
  const { field } = useController({
    name: FORM_FIELDS_ENUM.TITLE,
    control
  });
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isFocusOnInit) {
      inputRef.current?.focus();
    }
  }, [inputRef]);

  return (
    <>
      <TextInput
        inputRef={inputRef}
        // when the input is focused, the cursor should be at the end of the text
        onFocus={e => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
        variant="standard"
        disabled={isReadOnly}
        className="case-title"
        {...field}
        placeholder={t('writeATitle').toString()}
        multiline
        InputProps={{
          disableUnderline: true
        }}
      />
    </>
  );
};

export default CaseTitle;
