import React from 'react';
import type { ICase } from 'app/mobxStore/types';
import { useRootStore } from '../../mobxStore';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import Chip from 'app/components/chip/Chip';

const getCaseStatus = (kase: ICase, currentUserId: string): React.JSX.Element | null => {
  const onClick = (): void => {
    void kase.setStandardProcedureToServer(false);
    kase.setStandardProcedureToStore(false);
    kase.setIsReady(false);
    toast.success("So it's not a standard procedure. Don't forget to huddle.");
  };

  const ready = kase.data.isReady;

  const sentToAttending = kase.data.assistantFilledBrief;

  if (ready && kase.data.standardProcedure) {
    return (
      <Chip
        classNames={'case-status standard-proc'}
        text={'standardProcedureChip'}
        color={'success'}
        onClick={onClick}
      />
    );
  }
  if (ready) {
    return <Chip classNames={'case-status'} text={'huddled'} color={'success'} />;
  }
  if (sentToAttending) {
    return <Chip classNames={'case-status'} text={'sentToSurgeon'} color={'notification'} />;
  }
  return null;
};

const CaseStatusChip = (props: { case: ICase }): React.JSX.Element | null => {
  const { case: kase } = props;
  const { userStore } = useRootStore();
  const currentUserId = userStore.loggedInUser.data.id;

  return getCaseStatus(kase, currentUserId);
};

export default observer(CaseStatusChip);
