// CaseRoom.tsx
import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import { FORM_FIELDS_ENUM, type IFormData } from './types'; // Adjust the import path
import { useTranslation } from 'react-i18next';
import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';

interface IProps {
  control: Control<IFormData>;
}

const ROOM_FONT_SIZE = 9;
const ROOM_INPUT_WIDTH = 60;

const CaseRoom: React.FC<IProps> = ({ control }) => {
  const { t } = useTranslation();
  const { field } = useController({
    name: FORM_FIELDS_ENUM.ROOM,
    control
  });

  const [roomInputWidth, setRoomInputWidth] = useState(ROOM_INPUT_WIDTH);

  useEffect(() => {
    if (field.value && field.value.length * ROOM_FONT_SIZE > ROOM_INPUT_WIDTH) {
      setRoomInputWidth(field.value.length * ROOM_FONT_SIZE);
    } else {
      setRoomInputWidth(ROOM_INPUT_WIDTH);
    }
  }, [field.value]);

  return (
    <TextField
      className="room-input"
      variant="standard"
      {...field}
      InputProps={{
        style: { width: `${roomInputWidth}px` },
        disableUnderline: true
      }}
      placeholder={t('room').toString()}
    />
  );
};

export default CaseRoom;
