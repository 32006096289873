import React from 'react';
import type { IUserSelectOption } from 'app/mobxStore/types';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import variables from 'app/utils/variables.module.scss';
import './UserAvatar.scss';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

const colors = [
  variables.blue,
  variables.pink2,
  variables.red3,
  variables.yellow2,
  variables.green,
  variables.red2,
  variables.pink1
];

const numberFromText = (text: string): string => {
  const charCodes = text
    .split('')
    .map(char => char.charCodeAt(0))
    .join('');
  return colors[parseInt(charCodes, 10) % colors.length];
};

const getAvatarContent = (user: IUserSelectOption, nameInitials: string): React.JSX.Element => {
  if (user.avatar !== '' && user.avatar !== null && user.avatar !== undefined) {
    return <img className="avatar-image" src={user.avatar} />;
  }
  return <div className="avatar-text">{nameInitials}</div>;
};

const getAvatarSize = (size: string | undefined): string => {
  if (size === 'small') {
    return '22px';
  }
  if (size === 'medium') {
    return '28px';
  }
  if (size === 'large') {
    return '68px';
  }
  return '40px';
};

const UserAvatar = (props: {
  user: IUserSelectOption;
  size?: string;
  tempInitials?: string;
}): React.JSX.Element => {
  const { user, size, tempInitials } = props;
  const nameInitials =
    user.firstName?.length > 0 && user.lastName?.length > 0
      ? `${user.firstName[0]}${user.lastName[0]}`
      : tempInitials && tempInitials?.length > 0
      ? tempInitials[0]
      : user.label?.length > 0
      ? user.label[0]
      : '?';

  if (nameInitials === '?') {
    ErrorMonitor.captureException(
      new Error('UserAvatar: cannot generate initials, strings are empty')
    );
  }

  const bgColor = numberFromText(nameInitials);
  const avatarSize = getAvatarSize(size);
  return (
    <div className={clsx('avatar-container', [size])}>
      <Avatar
        sx={{
          bgcolor: bgColor,
          height: avatarSize,
          width: avatarSize
        }}
        style={{ borderColor: variables.primaryYellow }}
      >
        {getAvatarContent(user, nameInitials)}
      </Avatar>
    </div>
  );
};

export default React.memo(UserAvatar);
