import type { Identify } from '@amplitude/analytics-browser';
import * as amplitude from '@amplitude/analytics-browser';
import { isStringSet } from '../helper';
import type { IAmplSessionData, IAmplUserData, ICase } from '../../mobxStore/types';
import AUTH_CONFIG from '../auth0Service/auth0ServiceConfig';
import * as Sentry from '@sentry/react';
import { lite } from '../apiService/apiService';
import { log } from 'debug';

const DEBUG_EVENTS = false; // DO NOT COMMIT AS TRUE

if (!isStringSet(process.env.REACT_APP_AMPLITUDE_KEY)) {
  throw new Error('REACT_APP_AMPLITUDE_KEY is not defined');
}

amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY as string, '', {
  useBatch: true,
  flushIntervalMillis: 10 * 1000,
  flushQueueSize: 30,
  defaultTracking: true
});

class AmplService {
  EVENTS = {
    LOGIN: 'Login: Success',
    LOGIN_START: 'Login: Start',
    CALLBACK_START: 'Callback: Start',
    CALLBACK_END: 'Callback: End',
    HOME_QUICK_CREATE_CASE: 'Home: Quick create case',
    HOME_MY_ACCOUNT: 'Home: My account',
    ADD_A_CASE_CREATE: 'Add a case: Create',
    ADD_A_CASE_X: 'Add a case: X',
    CASE_BACK: 'Case: Back',
    CASE_APPROVE_HUDDLE: 'Case: Approve huddle',
    MENU_INVITE: 'Menu: Invite',
    HOME_INVITE: 'Home: Invite',
    INVITE_CREATE: 'Invite: Create',
    INVITE_SHARE: 'Invite: Share',
    INVITE_COPY: 'Invite: Copy',
    INVITE_CREATE_ANOTHER: 'Invite: Create another',
    INVITE_BACK: 'Invite: Back',
    CASE_ADD_USER: 'Case: Add user',
    HOME_CASE_LIST_ITEM: 'Home: Case list item',
    CASE_EDIT_MY_PREFERENCES: 'Case: Edit my preferences',
    EDIT_PREFERENCES_SAVE: 'Edit preferences: Save',
    EDIT_PREFERENCES_BACK: 'Edit preferences: Back',
    CASE_USE_PREFERENCES: 'Case: Use preferences',
    CASE_UNDO_USE_PREFERENCES: 'Case: Undo use preferences',
    CASE_TAB: 'Case: Tab',
    CHAT_MESSAGE_FIELD_FOCUSED: 'Chat: Message field focused',
    CHAT_SEND: 'Chat: Send',
    CHAT_LIKE: 'Chat: Like',
    HUDDLE_UPLOADED_IMAGE: 'Huddle: Uploaded image',
    CASE_FIELD_CHANGED: 'Case: Field changed',
    CASE_SEARCH_TEMPLATE: 'Case: Search template',
    SEARCH_TEMPLATE_CHOOSE: 'Search template: Choose',
    SEARCH_TEMPLATE_BACK: 'Search template: Back',
    CASE_PROCEDURE_SUGGESTION: 'Case: Procedure suggestion',
    SEARCH_TEMPLATE_DELETE_INPUT: 'Search template: Delete input',
    LOGIN_CALLBACK_TIMEOUT: 'Login: Callback timeout',
    LOGIN_AUTH_EVENT_NOT_FIRED: 'Login: Auth event not fired',
    ACCOUNT_USER_PROFILE: 'Account: User profile',
    ACCOUNT_ENABLE_NOTIFICATIONS: 'Account: Enable notifications',
    ACCOUNT_CONTACT_US: 'Account: Contact us',
    ACCOUNT_LOGOUT: 'Account: Logout',
    ERROR_PAGE_VIEWED: 'Error page: Viewed',
    ERROR_PAGE_RETURN_TO_BOARD: 'Error page: Return to board',
    ERROR_PAGE_REPORT_ISSUE: 'Error page: Report issue',
    ERROR: 'Error',
    TRACE_GETTING_TOKEN: 'Trace: Getting token',
    TRACE_GET_TOKEN_SUCCESS: 'Trace: Get token success',
    TRACE_GET_TOKEN_ERROR: 'Trace: Get token error',
    TRACE_REDIRECTING_TO_LOGIN: 'Trace: Redirecting to login',
    CASE_OPTION_LONG_PRESS: 'Case: option long pressed',
    ONBOARDING_UPLOAD_PHOTO_CLICKED: 'Onboarding: Upload photo clicked',
    HOME_CASE_LIST_ITEM_FOLLOW: 'Home: Case list item follow',
    HOME_CASE_LIST_ITEM_UNFOLLOW: 'Home: Case list item unfollow',
    HOME_CASE_LIST_ITEM_HUDDLE: 'Home: Case list item huddle',
    FOLLOWERS_SELECT_PANEL_APPLY: 'Followers select panel: Apply',
    FOLLOWERS_SELECT_PANEL_BACK: 'Followers select panel: Back',
    FOLLOWERS_SELECT_PANEL_ADD: 'Followers select panel: Add',
    FOLLOWERS_SELECT_PANEL_REMOVE: 'Followers select panel: Remove',
    PUSH_NOTIFICATIONS_PROMPT_SHOWN: 'Push notifications prompt: shown',
    PUSH_NOTIFICATIONS_PROMPT_NO_RESPONSE: 'Push notifications prompt: no response',
    PUSH_NOTIFICATIONS_PROMPT_BUTTON: 'Push notifications prompt: button clicked',
    HANDLE_EXT_ID: 'Handle ext id',
    HANDLE_EXT_ID_BEFORE_LOGIN: 'Handle ext id before login',
    HANDLE_EXT_ID_AFTER_LOGIN: 'Handle ext id after login'
  };

  ATTRIBUTES = {
    TAB: 'Tab',
    SELECTED_TEMPLATE: 'Selected template',
    IS_FROM_RECOMMENDATIONS: 'Is from recommendations',
    INPUT_VALUE: 'Input value',
    CASE_ID: 'Case ID',
    CASE_SPECIALTY_TITLE: 'Specialty title',
    CASE_STATE: 'Case state',
    CASE_SITE: 'Site',
    CASE_SITE_ID: 'Site ID',
    CASE_TEMPLATE: 'Template',
    FIELD_ID: 'Field ID',
    FIELD_OLD_VALUE: 'Field old value',
    FIELD_OLD_YOUR_OWN_VALUE: 'Field old your own value',
    FIELD_NEW_VALUE: 'Field new value',
    FIELD_NEW_YOUR_OWN_VALUE: 'Field new your own value',
    CASE_TITLE: 'Case title',
    INVITE_ID: 'Invite ID',
    ERROR_MESSAGE: 'Error message',
    IS_PWA: 'Is PWA',
    IS_ONE_SIGNAL_ENABLED: 'Is OneSignal enabled',
    IS_PUSH_OPT_IN: 'Is push opt in',
    IS_NEW_SITE: 'Is new site',
    PUSH_PROMPT_BUTTON: 'Push prompt button',
    DELAYED: 'Delayed',
    EXT_ID_SET: 'Ext id set'
  };

  identify: Identify | null = null;
  sessionAttributes = {};

  setData(userData: IAmplUserData, sessionData: IAmplSessionData): void {
    if (this.identify !== null) {
      return;
    }
    this.identify = new amplitude.Identify()
      .set('roles', userData.roles)
      .set('department', AUTH_CONFIG.audPrefix)
      .set('services', userData.services);

    if (lite) {
      this.identify.set('email', userData.email);
    }
    amplitude.setUserId(userData.id);
    amplitude.identify(this.identify);

    this.sessionAttributes = {
      IS_PWA: sessionData.isPwa,
      IS_ONE_SIGNAL_ENABLED: sessionData.isOneSignalEnabled,
      IS_PUSH_OPT_IN: sessionData.isPushOptIn
    };
  }

  sendEvent(event: string, md = {}): void {
    const eventProps = { ...md, ...this.sessionAttributes };
    amplitude.logEvent(event, eventProps);

    if (DEBUG_EVENTS) {
      log('Amplitude event:', event, JSON.stringify(eventProps));
    }
    Sentry.addBreadcrumb({
      category: 'analytics',
      message: `event: ${event}, md: ${JSON.stringify(eventProps)}`,
      level: 'info'
    });
  }

  // // eslint-disable-next-line class-methods-use-this
  // processLocation(location) {
  //   const { search, pathname } = location;
  //
  //   const pathParts = pathname ? pathname.split('/') : [];
  //   pathParts.shift();
  //   const page = pathParts[0];
  //   const tab = pathParts.length > 1 ? pathParts[1] : '';
  //   return {
  //     search,
  //     page,
  //     tab,
  //     fullPath: `${pathname}${search}`
  //   };
  // }

  sendCaseEvent(event: string, kase: ICase, extraMd = {}): void {
    this.sendEvent(event, {
      [this.ATTRIBUTES.CASE_ID]: kase.id,
      [this.ATTRIBUTES.CASE_STATE]: kase.data.state,
      [this.ATTRIBUTES.CASE_SITE]: kase.data.site.name,
      [this.ATTRIBUTES.CASE_SITE_ID]: kase.data.site.id,
      [this.ATTRIBUTES.CASE_SPECIALTY_TITLE]: kase.data.specialtyTitle,
      [this.ATTRIBUTES.CASE_TEMPLATE]: kase.data.procedureTitle,
      [this.ATTRIBUTES.CASE_TITLE]: kase.data.displayId,
      ...extraMd
    });
  }
}

const instance = new AmplService();

export default instance;
