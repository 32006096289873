import { useRootStore } from 'app/mobxStore';
import type {
  ICareTeamDefaults,
  IDynamicField,
  IDynamicFieldValues,
  IFullCase,
  IGetFieldValuesCount
} from '../../mobxStore/types';
import { getFields } from './helper';
import { CASE_STATE } from 'app/consts';
import { useForm, type UseFormReset } from 'react-hook-form';
import type { Control, FormState, UseFormHandleSubmit } from 'react-hook-form/dist/types/form';
import { log } from 'debug';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

interface IUseBriefForm {
  fields: IDynamicField[];
  caseId: string;
  caseState: CASE_STATE;
  submitFunction: (data: IDynamicFieldValues) => Promise<void>;
  control: Control<IDynamicFieldValues>;
  formState: FormState<IDynamicFieldValues>;
  handleSubmit: UseFormHandleSubmit<IDynamicFieldValues>;
  allValues: IDynamicFieldValues;
  submitDefault: (data: IDynamicFieldValues) => Promise<void>;
  reset: UseFormReset<any>;
  loadTemplate: () => Promise<void>;
  setFocus: (fieldId: string) => void;
}

const getDefaultValues = (
  fields: IDynamicField[],
  overRides: ICareTeamDefaults | undefined = undefined
): IDynamicFieldValues => {
  let defaultValues = {};
  fields.forEach(field => {
    const overRide = overRides !== undefined ? overRides.values[field.id] : undefined;
    const value = overRide !== undefined ? overRide.values : field.value;

    defaultValues = { ...defaultValues, [field.id]: value };
    if (field.hasOther) {
      const otherValue =
        overRide !== undefined ? overRide.otherValues : field.otherFieldProps.value;
      defaultValues = {
        ...defaultValues,
        [`${field.id}-other`]: otherValue
      };
    }
  });
  return defaultValues;
};

export const useBriefForm = (editMode: boolean): IUseBriefForm => {
  const { careTeamDefaultsStore, needsBriefReview, needsBriefApproval, caseStore, userStore } =
    useRootStore();
  const { refreshForm } = caseStore;
  const openedCase = caseStore.openedCase as IFullCase;
  const userData = userStore.loggedInUser.data;

  const getFieldValuesCount = (fieldId: string): IGetFieldValuesCount => {
    return caseStore.getFieldValuesCount(fieldId);
  };

  const fields = getFields(openedCase, userData.roles, editMode, getFieldValuesCount);
  const defaultValues = getDefaultValues(fields);
  const procedureId = openedCase.basicCase.data.procedureId as string;
  const { setFocus, watch, reset, control, handleSubmit, formState } = useForm<any>({
    defaultValues
  });
  const allValues = watch();
  useEffect(() => {
    openedCase.basicCase.setIsResetting(true);
    reset(defaultValues);
  }, [refreshForm]);

  const submitFunction = async (data: IDynamicFieldValues): Promise<void> => {
    try {
      if (needsBriefApproval) {
        await openedCase.basicCase.updateCaseMetaDataToServer({
          state: CASE_STATE.READY,
          isReady: true
        });
        openedCase.basicCase.setState(CASE_STATE.READY);
        return;
      }
      if (needsBriefReview) {
        const assistantFilledBrief = openedCase.basicCase.data.state === CASE_STATE.IN_BRIEF;
        await openedCase.basicCase.updateCaseMetaDataToServer({
          state: CASE_STATE.BRIEF_IN_REVIEW,
          assistantFilledBrief
        });
        openedCase.basicCase.setState(CASE_STATE.BRIEF_IN_REVIEW);
      }
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
      toast.error(`Sorry, that didn't work... 🤷‍. Please try again.`);
      log(error);
    }
  };

  const loadTemplate = async (): Promise<void> => {
    const procedureId = openedCase.basicCase.data.procedureId;
    if (procedureId === null) {
      return;
    }
    const savedDefault = careTeamDefaultsStore.getDefault(
      openedCase.basicCase.data.attendingId,
      procedureId
    );
    if (savedDefault === undefined) {
      return;
    }

    const defaultValues = getDefaultValues(fields, savedDefault);
    openedCase.basicCase.setIsResetting(true);
    reset(defaultValues);
  };

  const submitDefault = async (data: IDynamicFieldValues): Promise<void> => {
    try {
      await careTeamDefaultsStore.saveDefault(
        openedCase.basicCase.data.attendingId,
        procedureId,
        fields,
        data
      );
    } catch (error) {
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
      ErrorMonitor.captureException(error as Error);
      log(error);
    }
  };

  return {
    fields,
    caseId: openedCase.basicCase.id,
    caseState: openedCase.basicCase.data.state,
    submitFunction,
    control,
    handleSubmit,
    formState,
    allValues,
    submitDefault,
    reset,
    loadTemplate,
    setFocus
  };
};
