import React, { useEffect, useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import type { ICase } from 'app/mobxStore/types';
import clsx from 'clsx';
import DailyHuddleStats from './DailyHuddleStats';
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  DragOverlay,
  type DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import CaseListItem from 'app/components/orBoard/caseListItem/CaseListItem';
import EmptyList from 'app/components/orBoard/emptyList/EmptyList';
import NewCaseFab from 'app/components/orBoard/newCaseFab/NewCaseFab';
import './CasesList.scss';
import { calcIndexInDay } from './helper';
import { isSameDate } from '../../../mobxStore/timeHelper';
import SuggestToInvitePanel from './SuggestToInvitePanel';
import { getClickedUnfollowTooltip } from 'app/mobxStore/storage';

const CasesList = (): React.JSX.Element => {
  const { caseStore, liteNonSurgeon, showCasesToggle, userStore } = useRootStore();
  const [currentItemDragged, setCurrentItemDragged] = useState<undefined | ICase>(undefined);

  const handleScrollToCase = (id: string): void => {
    const element = document.getElementById(id);
    if (element != null) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
        caseStore.setIsAutoScrolling(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (caseStore.lastCreatedCase === null) {
      return;
    }
    // tz: caseDate is in site tz and selectedDateFilter is not dependent on site tz
    if (
      !isSameDate(new Date(caseStore.lastCreatedCase.data.caseDate), caseStore.selectedDateFilter)
    ) {
      return;
    }
    handleScrollToCase(caseStore.lastCreatedCase.id);
  }, [caseStore.lastCreatedCase]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;
    if (over === null) {
      setCurrentItemDragged(undefined);
      return;
    }
    if (active.id === over.id) {
      setCurrentItemDragged(undefined);
      return;
    }
    const activeCase = caseStore.sortedCases.find(item => item.id === active.id);
    const overCase = caseStore.sortedCases.find(item => item.id === over.id);
    if (activeCase === undefined || overCase === undefined) {
      setCurrentItemDragged(undefined);
      return;
    }
    const direction = activeCase.data.indexInDay > overCase.data.indexInDay ? 'down' : 'up';
    const newIndexInDay = calcIndexInDay(caseStore.sortedCases, over.id, direction);

    activeCase.setIndexInDayToStore(newIndexInDay);
    setCurrentItemDragged(undefined);
  };

  const handleDragStart = (event: DragStartEvent): void => {
    const caseItem = caseStore.sortedCases.find(item => item.id === event.active.id);
    setCurrentItemDragged(caseItem);
  };

  const getGhostItem = (kase: ICase): React.JSX.Element => {
    return <CaseListItem key={kase.id} kase={kase} isGhost isDragging={false} />;
  };

  const ghostItem = currentItemDragged !== undefined ? getGhostItem(currentItemDragged) : null;
  const hasCases = caseStore.sortedCases.length > 0;

  useEffect(() => {
    if (!hasCases || getClickedUnfollowTooltip()) {
      return;
    }
    let caseId = '';
    caseStore.sortedCases.forEach(kase => {
      if (
        kase.data.caseFollowers.some(
          follower => follower.userId === userStore.loggedInUser.data.id
        ) &&
        caseId === ''
      ) {
        caseId = kase.id;
      }
    });
    caseStore.setCaseToShowUnfollowTooltip(caseId);
  }, [caseStore.selectedDateFilter]);

  if (!hasCases) {
    return <EmptyList />;
  }

  return (
    <div className="cases-list-container">
      <div
        className={clsx('cases-list', {
          'no-toggles': !showCasesToggle,
          'no-pickers': liteNonSurgeon
        })}
      >
        <DailyHuddleStats />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={caseStore.sortedCases} strategy={verticalListSortingStrategy}>
            {caseStore.sortedCases.map(kase => (
              <div key={kase.id} id={kase.id}>
                <CaseListItem
                  key={kase.id}
                  kase={kase}
                  isDragging={currentItemDragged !== undefined && currentItemDragged.id === kase.id}
                />
              </div>
            ))}
          </SortableContext>
          {ghostItem !== null && <DragOverlay>{ghostItem}</DragOverlay>}
        </DndContext>
        <NewCaseFab />
        <SuggestToInvitePanel />
      </div>
    </div>
  );
};

export default observer(CasesList);
