import { useContext } from 'react';
import AppContext from 'app/AppContext';
import { Navigate } from 'react-router-dom';
import { useRootStore } from 'app/mobxStore';

const useRoutes = () => {
  const appContext = useContext(AppContext);
  const { userStore } = useRootStore();
  const { routes } = appContext;
  const user = userStore.loggedInUser;

  let parsedRoutes = routes;
  if (!user.data.roles.some(r => ['attending', 'resident'].includes(r))) {
    const cRouteIndex = routes.findIndex(r => r.path === '/cases');
    const tArr = [...routes];
    tArr[cRouteIndex] = {
      ...tArr[cRouteIndex],
      element: <Navigate to="/cases" replace />
    };
    parsedRoutes = tArr;
  }
  return parsedRoutes;
};

export default useRoutes;
