import React from 'react';
import type { IDynamicField } from 'app/mobxStore/types';
import { OTHER_OPTION } from '../../../utils/form/shared';
import clsx from 'clsx';
import { fieldNeedsAttention } from './helper';
import { useRootStore } from 'app/mobxStore';

interface IProps {
  field: IDynamicField;
}

const getChipsValue = (field: IDynamicField): string[] => {
  return field.value;
};
const ChipsDisplayWidget = (props: IProps): React.JSX.Element | null => {
  const { field } = props;

  const { caseStore } = useRootStore();
  const { openedCase } = caseStore;

  if (!openedCase) {
    throw new Error('No case found');
  }

  const chips = getChipsValue(field);

  if (chips.length === 0) {
    return null;
  }
  const hasOtherValues = chips.includes(OTHER_OPTION);

  const parsedChips = hasOtherValues
    ? [...chips.filter(chip => chip !== OTHER_OPTION), ...field.otherFieldProps.value]
    : chips;

  return (
    <div className="form-group">
      <div
        className={clsx('form-group-label', {
          needsAttention: fieldNeedsAttention(field, caseStore.currentUserPrevLastSeen)
        })}
      >
        <div className="label-container">{field.label}</div>
      </div>
      {parsedChips.map(chip => {
        return (
          <div key={chip} className="chip-display-only">
            {chip === OTHER_OPTION ? field.otherFieldProps.value : chip}
          </div>
        );
      })}
    </div>
  );
};

export default ChipsDisplayWidget;
