export const getAud = (first: string): string => {
  if (window.location.host.endsWith(':3000')) {
    return process.env.REACT_APP_LOCALHOST_AUD ?? '';
  }
  if (first === 'stage-app' || first === 'r-stage-app') {
    return 'stage';
  }
  if (first.endsWith('chf-tay-dev') || first.endsWith('r-chiefy')) {
    return 'chf-tay-dev';
  }
  if (first.endsWith('zipora-app') || first.endsWith('zipora')) {
    return 'zipora';
  }
  if (first.startsWith('r-')) {
    return first.slice(2);
  }
  return first;
};

export const isStringSet = (val: string | undefined | null): boolean => {
  return val !== undefined && val !== null && val !== '';
};
