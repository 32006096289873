import { toZonedTime } from 'date-fns-tz';

export const isSameDate = (d1: Date, d2: Date): boolean => {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
};

export const isToday = (d: Date, tz: string): boolean => {
  const today = toZonedTime(new Date(), tz);
  return isSameDate(d, today);
};

export const isTomorrow = (d: Date, tz: string): boolean => {
  const tomorrow = toZonedTime(new Date(), tz);
  tomorrow.setDate(Number(tomorrow.getDate()) + 1);

  return isSameDate(d, tomorrow);
};
