import { processPMMFields } from './PMMFields';
import type {
  ICaseBasicData,
  ICaseReferencedData,
  IDynamicField,
  IGetFieldValuesCount,
  IRoleData
} from 'app/mobxStore/types';

// eslint-disable-next-line import/prefer-default-export
export const getBriefFields = (
  caseData: ICaseBasicData,
  referencedData: ICaseReferencedData,
  userRoles: IRoleData[],
  editMode: boolean,
  getFieldValuesCount: (fieldId: string) => IGetFieldValuesCount
): IDynamicField[] => {
  if (referencedData.procedureData === undefined) {
    return [];
  }
  return processPMMFields(caseData, referencedData, userRoles, editMode, getFieldValuesCount);
};
