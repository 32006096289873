import React, { useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import SurgeonSelect from 'app/components/fields/SurgeonSelect';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import HuddleTabs from './HuddleTabs';
import AssistantSelect from 'app/components/fields/AssistantSelect';
import FollowersSelect from 'app/components/fields/FollowersSelect';
import CaseStatusChip from 'app/components/caseStatusChip/CaseStatusChip';
import CaseDate from 'app/components/fields/CaseDate';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import type { Control, FormState } from 'react-hook-form/dist/types/form';
import { isServiceWithAssistants } from 'app/components/form/helper';
import CaseTitle from '../fields/CaseTitle';
import { isDateInPast } from '../../mobxStore/caseStoreHelper';
import CaseListItemMenu from 'app/components/orBoard/caseListItem/caseListItemMenu/CaseListItemMenu';
import './FillCaseMetaForm.scss';
import { lite } from '../../services/apiService/apiService';
import type { ICase, IUserDataWithRoles, IUserSelectOption } from '../../mobxStore/types';
import { parseUserToSelectOption } from '../../mobxStore/MetaDataStore';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../consts';
import CaseRoom from '../fields/CaseRoom';

interface IProps {
  control: Control<IFormData>;
  formState: FormState<IFormData>;
  allValues: IFormData;
  handleClose: () => Promise<void>;
  loadTemplate?: () => Promise<void>;
  isCommentsHeader?: boolean;
}

const parseSurgeonsOptions = (
  surgeonsOptions: IUserSelectOption[],
  openedCase: ICase
): IUserSelectOption[] => {
  const isAttendingNotActive = openedCase.data.attending?.isActive === false;
  if (!isAttendingNotActive) {
    return surgeonsOptions;
  }
  const attending = openedCase.data.attending as IUserDataWithRoles;
  return [parseUserToSelectOption(attending), ...surgeonsOptions];
};

const FormHeader = (props: IProps): React.JSX.Element => {
  const { handleClose, allValues, control, isCommentsHeader } = props;
  const { caseStore, metaDataStore, liteNonSurgeon } = useRootStore();
  const openedCase = caseStore.openedCase;
  const { t } = useTranslation();
  const [isTitleEditable, setIsTitleEditable] = useState(!isCommentsHeader);
  const [dateToPostponeCase, setDateToPostponeCase] = useState<Date>();

  // unused expand functionality
  // const [isExpanded, setIsExpanded] = useState(!isCommentsHeader);
  const isExpanded = true;

  if (openedCase === null) {
    return <></>;
  }

  const { sites } = metaDataStore;
  const siteId = allValues[FORM_FIELDS_ENUM.SERVICE]?.value;
  const surgeonsOptions = siteId
    ? metaDataStore.getSurgeonsOptionsBySite(siteId)
    : metaDataStore.surgeonsOptions;
  const assistantsOptions = siteId
    ? metaDataStore.getAssistantsOptionsBySite(siteId)
    : metaDataStore.assistantsOptions;

  const getCareTeamOptions = (): IUserSelectOption[] => {
    const careTeamOptions = siteId
      ? metaDataStore.getCareTeamOptionsBySite(siteId)
      : metaDataStore.careTeamOptions;
    if (lite) {
      return careTeamOptions.filter(
        option => option.roles.find(r => r.role === ROLES.ATTENDING) === undefined
      );
    }
    return careTeamOptions;
  };

  const parsedSurgeonsOptions = parseSurgeonsOptions(surgeonsOptions, openedCase.basicCase);
  const showAssistantSelect =
    allValues[FORM_FIELDS_ENUM.SERVICE] === undefined
      ? false
      : isServiceWithAssistants(sites, allValues[FORM_FIELDS_ENUM.SERVICE].value);

  const isFollowersSelectReadyOnly = isDateInPast(
    openedCase.basicCase.data.caseDate,
    openedCase.basicCase.store.rootStore.tz
  );

  const handleToggleEditableTitle = (): void => {
    setIsTitleEditable(!isTitleEditable);
  };

  const getPatientInfo = (): React.JSX.Element | null => {
    const initials = openedCase.basicCase.data.patientInitials;
    const mrn = openedCase.basicCase.data.mrn;

    if (!initials && !mrn) {
      return null;
    }

    return (
      <div className="patient-info">
        {initials && <div className="initials">{initials}</div>}
        {mrn && <div className="number">{mrn}</div>}
      </div>
    );
  };

  // const handleExpand = async (): Promise<void> => {
  //   setIsExpanded(!isExpanded);
  // };

  const indexInDayForAttending = caseStore.getCaseIndexInDayForAttending(openedCase.basicCase.data);
  const patientInfo = getPatientInfo();

  return (
    <div className={clsx('form-header', { expanded: isExpanded })}>
      <HuddleTabs handleClose={handleClose} />
      <div
        className="title-container"
        onBlur={() => {
          if (isCommentsHeader && isTitleEditable) {
            setTimeout(() => {
              handleToggleEditableTitle();
            });
          }
        }}
      >
        {isTitleEditable ? (
          <CaseTitle control={control} isFocusOnInit={isCommentsHeader} />
        ) : (
          <div
            className="title-read-only-container"
            onClick={() => {
              handleToggleEditableTitle();
            }}
          >
            <div className="title-read-only">{openedCase.basicCase.data.displayId}</div>
          </div>
        )}
        {/* {isCommentsHeader && (
          <Button
            classNames={clsx('circle-button expand-button', { expanded: isExpanded })}
            startIcon={<ArrowDownIcon stroke={variables.black} height={14} />}
            onClick={handleExpand}
          />
        )} */}
        <CaseListItemMenu
          case={openedCase.basicCase}
          dateToPostponeCase={dateToPostponeCase}
          setDateToPostponeCase={setDateToPostponeCase}
        />
      </div>
      {patientInfo}
      <div className="selects">
        <div className="selects-row">
          <SurgeonSelect
            control={control}
            options={parsedSurgeonsOptions}
            allValues={allValues}
            isReadOnly={lite}
          />
          <div className="case-info-chip">{`${t('caseNumber')}${indexInDayForAttending}`}</div>
          <div className="case-info-chip">
            <CaseRoom control={control} />
          </div>
          {!liteNonSurgeon && (
            <CaseDate
              control={control}
              allValues={allValues}
              setDateToPostponeCase={setDateToPostponeCase}
              isReadOnly={liteNonSurgeon}
            />
          )}
        </div>
        {showAssistantSelect && (
          <div className="selects-row">
            <AssistantSelect control={control} options={assistantsOptions} allValues={allValues} />
            {/* {!isExpanded && (
              <UserAvatarGroup
                users={openedCase.basicCase.data.caseFollowers.map(f => parseUserToSelectOption(f.user))}
                maxUsers={5}
                size="medium"
              />
            )} */}
          </div>
        )}
      </div>
      {isExpanded && (
        <FollowersSelect
          control={control}
          options={getCareTeamOptions()}
          isReadOnly={isFollowersSelectReadyOnly}
        />
      )}
      {openedCase && <CaseStatusChip case={openedCase.basicCase} />}
    </div>
  );
};

export default observer(FormHeader);
