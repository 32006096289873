import {
  CaseStatusEnum,
  type ICase,
  type ICaseBasicData,
  type ICaseFullData,
  type ICaseStore,
  type IDynamicField,
  type IDynamicFieldValues,
  type IFieldValuesCountSum,
  type IFullCase,
  type IGetFieldValuesCount,
  type IProcedure,
  type IRootStore,
  type ISelectOption,
  type IUpdateCaseInputWithoutProc,
  type IUpdateCaseInputWithProc,
  SavingStatus,
  TabsEnum
} from './types';
import { flow, makeAutoObservable } from 'mobx';
import Case from './Case';
import apiService from 'app/services/apiService';
import { formDataToCaseState, getDateKey, isCaseOfUser, updateFvcMap } from './caseStoreHelper';
import type { IAllFormData, IFormData } from 'app/components/fields/types';
import { camelizeKeys } from 'utils/humps';

import { submitBriefForm } from 'utils/form/submit';
import { type CASE_STATE, USER_CASE_ROLES } from 'app/consts';
import MomentAdapter from '@date-io/moment';
import type { Subscription } from 'zen-observable-ts';
import { log } from 'debug';
import { isTrue } from '../mobxStore/storage';
import { lite } from '../services/apiService/apiService';
import { parseInt } from 'lodash';
import { isStringSet } from '../services/helper';
import { fromZonedTime } from 'date-fns-tz';
import FullCase from './FullCase';
import ErrorMonitor from '../services/errorMonitor/errorMonitor';

const isMyCasesKey = 'isMyCases';
const cacheKeys = [isMyCasesKey];
const dateLib = new MomentAdapter();
const TIME_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';
const BEFORE_ONE_MONTH_DATE = dateLib
  .moment()
  .subtract(1, 'months')
  .startOf('day')
  .format(TIME_FORMAT);
const BEFORE_THREE_MONTH_DATE = dateLib
  .moment()
  .subtract(3, 'months')
  .startOf('day')
  .format(TIME_FORMAT);

class CaseStore implements ICaseStore {
  lastUpdate: number = 0;
  casesLoadingDone: boolean = false;
  selectedTabInHuddle = TabsEnum.CASE;
  tempComment: string = '';
  savingStatusInHuddle = SavingStatus.NONE;
  minDate = dateLib.moment().subtract(3, 'months').startOf('day').toDate();
  rootStore: IRootStore;
  items: ICase[] = [];
  lastCreatedCase: ICase | null = null;
  recentlyHuddledCase: ICase | null = null;
  caseToOpenNext: ICase | null = null;
  recentlyCreatedCaseId: string | null = null;
  openedCase: IFullCase | null = null;
  isNewCase: boolean = false;
  huddleScrolledToBottom: boolean = false;
  huddleScrolled: boolean = false;
  isAutoScrolling: boolean = false;
  lastHuddleScrollPosition: number = 0;
  selectedDateFilter: Date = new Date();
  selectedStatusFilter: CaseStatusEnum = CaseStatusEnum.CURRENT;
  isMyCases: boolean = false;
  isHuddleDialogOpen: boolean = false;
  isEditTemplateDialogOpen: boolean = false;
  isMyAccountDialogOpen: boolean = false;
  isInviteDialogOpen: boolean = false;
  isNewSiteInvite: boolean = false;
  isSettingsDialogOpen: boolean = false;
  subscription: Subscription | null = null;
  refreshForm: boolean = false;
  checkSubscriptionInterval;
  refreshSubscriptions = false;
  caseToShowUnfollowTooltip: string | null = null;

  fieldValuesCountAll = new Map<string, Map<string, number>>();
  fieldValuesCountAttendingAllProcs = new Map<string, Map<string, number>>();
  fieldValuesCountAttendingCurProc = new Map<string, Map<string, number>>();

  unreadCommentsSubscription: Subscription | null = null;
  unreadCommentsMap = new Map<string, number>();
  savingStatusTimeout: NodeJS.Timeout | null = null;
  lastFormChange: number = 0;
  isSaving: boolean = false;
  currentUserPrevLastSeen: Date | null = null;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      subscription: false
    });
    this.rootStore = rootStore;
    if (this.hasIsMyCasesSavedPref()) {
      this.setIsMyCases(this.getIsMyCasesSavedPref());
    }
    this.checkSubscriptionInterval = setInterval(() => {
      this.checkSubscription();
    }, 1000 * 5);
  }

  setRefreshSubscriptions(refresh: boolean): void {
    this.refreshSubscriptions = refresh;
  }

  updateCaseState(caseId: string, state: CASE_STATE): void {
    const curCase = this.items.find(c => c.id === caseId);
    if (curCase === undefined) {
      return;
    }
    curCase.data.state = state;
  }

  updateCaseAssistantFilledBrief(caseId: string, assistantFilledBrief: boolean): void {
    const curCase = this.items.find(c => c.id === caseId);
    if (curCase === undefined) {
      return;
    }
    curCase.setAssistantFilledBrief(assistantFilledBrief);
  }

  updateCaseIntraopText(caseId: string, intraopText: string): void {
    const curCase = this.items.find(c => c.id === caseId);
    if (curCase === undefined) {
      return;
    }
    curCase.data.intraopText = intraopText;
  }

  updateCaseIsReady(caseId: string, isReady: boolean): void {
    const curCase = this.items.find(c => c.id === caseId);
    if (curCase === undefined) {
      return;
    }
    curCase.setIsReady(isReady);
  }

  setSelectedStatusFilter(status: CaseStatusEnum): void {
    this.selectedStatusFilter = status;
  }

  get huddleRate(): number {
    let briefed = 0;
    let all = 0;
    this.myCases.forEach(c => {
      const isBriefed = c.data.isReady;
      if (isBriefed) {
        briefed += 1;
      }
      const isTodayOrBefore = new Date(c.data.caseDate) <= new Date();
      if (isBriefed || isTodayOrBefore) {
        all += 1;
      }
    });

    return briefed / all;
  }

  getIsMyCasesSavedPref(): boolean {
    return isTrue(localStorage.getItem(isMyCasesKey) ?? 'false');
  }

  hasIsMyCasesSavedPref(): boolean {
    return this.getLocalStorageData().has(isMyCasesKey);
  }

  setIsHuddleDialogOpen(isOpen: boolean): void {
    this.isHuddleDialogOpen = isOpen;
  }

  setIsEditTemplateDialogOpen(isOpen: boolean): void {
    this.isEditTemplateDialogOpen = isOpen;
  }

  setIsMyAccountDialogOpen(isOpen: boolean): void {
    this.isMyAccountDialogOpen = isOpen;
  }

  setIsInviteDialogOpen(isOpen: boolean): void {
    this.isInviteDialogOpen = isOpen;
  }

  setIsNewSiteInvite(isNewSite: boolean): void {
    this.isNewSiteInvite = isNewSite;
  }

  setIsSettingsDialogOpen(isOpen: boolean): void {
    this.isSettingsDialogOpen = isOpen;
  }

  setSelectedTab(tab: TabsEnum): void {
    this.selectedTabInHuddle = tab;
    if (this.savingStatusTimeout) {
      clearInterval(this.savingStatusTimeout);
      this.savingStatusTimeout = null;
    }
    this.savingStatusInHuddle = SavingStatus.NONE;
  }

  setIsSaving(isSaving: boolean): void {
    this.isSaving = isSaving;
  }

  setSavingStatus(savingStatus: SavingStatus): void {
    this.lastFormChange = Date.now();
    if (!this.savingStatusTimeout) {
      this.savingStatusInHuddle = savingStatus;
      this.savingStatusTimeout = setInterval(() => {
        if (this.isSaving) {
          return;
        }
        const now = Date.now();
        const timeSinceLastUpdate = now - this.lastFormChange;
        const randDelay = Math.floor(Math.random() * 500) + 1000;
        if (timeSinceLastUpdate >= randDelay) {
          this.savingStatusInHuddle = SavingStatus.SAVED;
          if (!this.savingStatusTimeout) {
            return;
          }
          clearInterval(this.savingStatusTimeout);
          this.savingStatusTimeout = null;
        }
      }, 1000);
    }
  }

  setTempComment(comment: string): void {
    this.tempComment = comment;
  }

  setCasesLoadingDone(done: boolean): void {
    this.casesLoadingDone = done;
  }

  createCase = flow(function* (this: ICaseStore, data: IFormData, ontologyVersion: string) {
    const { date, surgeon, assistant, service, title, description } = data;
    const isResidentOwnCase = assistant?.value === this.rootStore.userStore.loggedInUser.data.id;

    const state = formDataToCaseState(date, true, this.rootStore.tz, isResidentOwnCase);
    const fixedTimezoneDate = fromZonedTime(date, this.rootStore.metaDataStore.tz);
    const parsedDate = lite ? new Date() : fixedTimezoneDate;

    const parsedCase = {
      caseDate: parsedDate,
      displayId: title,
      description,
      attendingId: surgeon?.value,
      residentId: assistant?.value,
      siteId: service?.value,
      state,
      ontologyVersion,
      indexInDay: new Date().getTime()
    };

    // in lite mode, we need to save the date in local storage
    const overrideDate = lite ? fixedTimezoneDate : undefined;
    const newCaseData: ICaseFullData = yield apiService.createCase(parsedCase, overrideDate);

    const newCase = new Case(this, newCaseData.id, newCaseData);
    this.setRecentlyCreatedCaseId(newCase.id);
    yield newCase.handleDateChange(newCase.data.caseDate);
    return newCase;
  });

  updateCaseIndexInDay = flow(function* (this: ICaseStore, caseId: string, indexInDay: number) {
    yield apiService.updateCaseValues(caseId, { indexInDay });
  });

  fillCaseMetaDataWithOutProcedure = flow(function* (
    this: ICaseStore,
    caseId: string,
    data: IAllFormData
  ) {
    const { indexInDay, date, surgeon, assistant, service, title, description } = data;

    const parsedCase: IUpdateCaseInputWithoutProc = {
      caseDate: date,
      displayId: title,
      attendingId: surgeon?.value,
      residentId: assistant?.value,
      siteId: service?.value,
      description,
      indexInDay
    };
    const updatedCase = yield apiService.updateCaseValues(caseId, parsedCase);
    return new Case(this, updatedCase.id, updatedCase);
  });

  updateCaseProcedureToServer = flow(function* (
    this: ICaseStore,
    caseId: string,
    procedure: ISelectOption,
    ontologyVersion: string
  ) {
    const pVal = procedure.value;

    const pLabel = procedure.label;
    const pJson = yield this.getCaseMetaData(procedure?.value ?? '');

    const parsedCase: IUpdateCaseInputWithProc = {
      ontologyVersion,
      procedureId: pVal,
      procedureData: pJson,
      procedureTitle: pLabel,
      procedureTypeId: pJson.subType.procedureType.id,
      procedureTypeTitle: pJson.subType.procedureType.name,
      specialtyId: pJson.specialty.id,
      specialtyTitle: pJson.specialty.name,
      indexInDay: pJson.indexInDay
    };
    /* if form is different, need to re-create the case */
    const curCase = this.openedCase as IFullCase;
    const parsedCaseHasProcedure =
      parsedCase.procedureData !== null && parsedCase.procedureData !== undefined;

    const clearData =
      curCase.referencedData.procedureData !== null &&
      curCase.referencedData.procedureData !== undefined &&
      parsedCaseHasProcedure &&
      curCase.referencedData.procedureData.subType.form.id !== pJson.subType.form.id;
    if (clearData) {
      yield this.clearCaseData(caseId);
    }
    yield apiService.updateCaseValues(caseId, parsedCase);

    /* Decided not to take defaults from ontology, but always start from a clean case */
    // const defaults = getDefaultsFromProcedure(updatedCase.procedureData);
    // yield submitCaseProcedureDefaults(caseId, defaults);

    const caseData = yield apiService.getCase(caseId);
    const basicCase = new Case(this, caseData.id, caseData);

    return new FullCase(caseData, basicCase);
  });

  clearCaseData = flow(function* (this: ICaseStore, caseId: string) {
    try {
      yield apiService.clearCase(caseId);
    } catch (error) {
      log(error);
    }
  });

  getCaseMetaData = flow(function* (this: ICaseStore, procedureId: string) {
    const ontVersion = apiService.currentOntVersion();
    const ont = yield apiService.getOnt(`procedure-by-id/${procedureId}.json`, ontVersion);
    const pFile = yield fetch(ont.signedUrl);
    return camelizeKeys(yield pFile.json()) as IProcedure;
  });

  checkForFormChange(curProcedure: IProcedure, newProcedure: IProcedure): boolean {
    return curProcedure.subType.form.id !== newProcedure.subType.form.id;
  }

  addCase(caseToAdd: ICase): void {
    const curCaseIdx = this.items.findIndex(c => c.id === caseToAdd.id);
    if (curCaseIdx !== -1) {
      return;
    }
    this.items.push(caseToAdd);
    this.lastCreatedCase = caseToAdd;
  }

  setLastCreatedCase(caseToSet: ICase | null): void {
    this.lastCreatedCase = caseToSet;
  }

  setRecentlyHuddledCase(caseToSet: ICase | null): void {
    this.recentlyHuddledCase = caseToSet;
  }

  setCaseToOpenNext(caseToSet: ICase | null): void {
    this.caseToOpenNext = caseToSet;
  }

  setRecentlyCreatedCaseId(caseId: string | null): void {
    this.recentlyCreatedCaseId = caseId;
  }

  updateCase(caseToUpdate: ICase): void {
    const curCaseIdx = this.items.findIndex(c => c.id === caseToUpdate.id);
    if (curCaseIdx === -1) {
      return;
    }
    this.items[curCaseIdx] = caseToUpdate;
  }

  setIsMyCases(isMyCases: boolean): void {
    this.isMyCases = isMyCases;
    localStorage.setItem('isMyCases', isMyCases.toString());
  }

  setSelectedDateFilter(date: Date): void {
    this.selectedDateFilter = date;
  }

  setCases(cases: ICaseBasicData[]): void {
    this.items = cases.map(c => new Case(this, c.id, c));
  }

  setOpenedCase(openedCase: IFullCase | null): void {
    if (openedCase === null) {
      this.openedCase = null;
      void this.refreshFieldValuesCountSum();
      void this.loadAllCases(true);
      this.setRecentlyCreatedCaseId(null);
      return;
    }

    this.openedCase = openedCase;

    if (
      this.rootStore.userRoleInCase !== USER_CASE_ROLES.NONE &&
      isStringSet(openedCase.basicCase.data.procedureId)
    ) {
      void this.refreshFieldValuesCountSum();
    }

    if (this.rootStore.userRoleInCase === USER_CASE_ROLES.ATTENDING) {
      return;
    }

    const currentUserFollower = this.openedCase.basicCase.data.caseFollowers.find(
      f => f.userId === this.rootStore.userStore.loggedInUser.data.id
    );
    if (currentUserFollower) {
      this.setCurrentUserPrevLastSeen(currentUserFollower.lastSeen);
    } else {
      void this.openedCase.basicCase.upsertFollowerToServer(
        this.rootStore.userStore.loggedInUser.data.id
      );
    }
    const now = new Date();
    void this.openedCase.basicCase.updateLastSeenToServer(now);

    this.openedCase.basicCase.upsertFollowerWithLastSeenToStore(
      this.rootStore.userStore.loggedInUser.data.id,
      now
    );
  }

  submitBrief = flow(function* (
    this: ICaseStore,
    caseId: string,
    fields: IDynamicField[],
    values: IDynamicFieldValues,
    caseState: CASE_STATE
  ) {
    yield submitBriefForm(caseId, fields, values);
  });

  setIsNewCase(isNewCase: boolean): void {
    this.isNewCase = isNewCase;
    if (isNewCase) {
      this.setIsHuddleDialogOpen(true);
    }
  }

  setHuddleScrolledToBottom(isBottom: boolean): void {
    this.huddleScrolledToBottom = isBottom;
  }

  setHuddleScrolled(scrollTopValue: boolean): void {
    this.huddleScrolled = scrollTopValue;
  }

  setIsAutoScrolling(isAuto: boolean): void {
    this.isAutoScrolling = isAuto;
  }

  setLastHuddleScrollPosition(scrollTopValue: number): void {
    this.lastHuddleScrollPosition = scrollTopValue;
  }

  loadMyCases = flow(function* (this: ICaseStore, logError: boolean = true) {
    const cases = yield apiService.getMyCases(logError);
    this.setCases(cases);
  });

  loadAllCases = flow(function* (this: ICaseStore, short: boolean, logError: boolean = true) {
    if (lite) {
      if (this.rootStore.isLoggedInUserAttending) {
        yield this.loadMyCases(logError);
      } else {
        const cases = yield apiService.getLiteCases(logError);
        this.setCases(cases);
      }
      return;
    }

    const MIN_DATE = short ? BEFORE_ONE_MONTH_DATE : BEFORE_THREE_MONTH_DATE;
    const cases = yield apiService.getCases(MIN_DATE);
    this.setCases(cases);
  });

  setLastUpdate(lastUpdate: number): void {
    this.lastUpdate = lastUpdate;
  }

  checkSubscription(): void {
    const lastApiCallStr = localStorage.getItem('lastApiCall');
    if (lastApiCallStr === null) {
      return;
    }
    const now = Date.now();
    const lastApiCall = parseInt(lastApiCallStr, 10);
    const diffFromLastApiCall = now - lastApiCall;
    if (diffFromLastApiCall < 1000 * 15) {
      console.log('lastApiCall is less than 15 seconds');
      return;
    }
    if (this.lastUpdate < lastApiCall) {
      console.log('lastUpdate is older than lastApiCall');
      const err = new Error('CaseStore: lastUpdate is older than lastApiCall');
      ErrorMonitor.captureException(err);
      console.error(err);
      this.unSubscribeFromCases();
      void this.subscribeToCases();
    }
  }

  subscribeToCases = flow(function* (this: ICaseStore) {
    this.subscription = apiService.subscribeToCases(
      lite,
      this.rootStore.isLoggedInUserAttending,
      BEFORE_THREE_MONTH_DATE,
      casesData => {
        this.setLastUpdate(Date.now());
        this.setCases(casesData);
        this.setCasesLoadingDone(true);
      }
    );
    this.unreadCommentsSubscription = apiService.subscribeToCasesUnreadComments(
      (data: Map<string, number>) => {
        this.setUnreadCommentsMap(data);
      }
    );
  });

  unSubscribeFromCases(): void {
    if (this.subscription !== null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    if (this.unreadCommentsSubscription !== null) {
      this.unreadCommentsSubscription.unsubscribe();
      this.unreadCommentsSubscription = null;
    }
  }

  openCase = flow(function* (this: ICaseStore, id: string) {
    const caseData: ICaseFullData = yield apiService.getCase(id);
    if (caseData === null) {
      return null;
    }
    const basicCase = new Case(this, caseData.id, caseData);
    const fullCase = new FullCase(caseData, basicCase);
    this.setOpenedCase(fullCase);
    this.setIsHuddleDialogOpen(true);

    return caseData;
  });

  get myCases(): ICase[] {
    return this.items.filter(c => isCaseOfUser(c, this.rootStore.userStore.loggedInUser.data.id));
  }

  get filteredCasesByDate(): Map<string, ICase[]> {
    const cases = this.isMyCases ? this.myCases : this.items;
    const map = new Map<string, ICase[]>();
    cases.forEach(c => {
      const dateKey = getDateKey(new Date(c.data.caseDate));
      const casesArr = map.get(dateKey) ?? [];
      casesArr.push(c);
      map.set(dateKey, casesArr);
    });
    return map;
  }

  get filteredCases(): ICase[] {
    if (this.rootStore.liteNonSurgeon) {
      return this.items.filter(c => c.data.status === this.selectedStatusFilter);
    }
    return this.filteredCasesByDate.get(getDateKey(this.selectedDateFilter)) ?? [];
  }

  get sortedCases(): ICase[] {
    const clone = [...this.filteredCases];
    clone.sort((a, b) => a.data.indexInDay - b.data.indexInDay);
    return clone;
  }

  get sortedAllCases(): ICase[] {
    const clone = [...this.items];
    clone.sort((a, b) => a.data.indexInDay - b.data.indexInDay);
    return clone;
  }

  getCaseIndexInDayForAttending(caseData: ICaseBasicData): number {
    const surgeonTotalCases = this.sortedCases.filter(
      (c: ICase): boolean => c.data.attendingId === caseData.attendingId
    );
    const thisCaseIndexInDay =
      surgeonTotalCases.findIndex((c: ICase): boolean => c.id === caseData.id) + 1;
    return thisCaseIndexInDay;
  }

  hasCaseInDateByFilter(date: Date): boolean {
    return this.filteredCasesByDate.has(getDateKey(date));
  }

  hasNeedAttentionFilteredCasesInDay(date: Date): boolean {
    const casesInDate = this.filteredCasesByDate.get(getDateKey(date));
    if (casesInDate === undefined) {
      return false;
    }

    return casesInDate.some(c => {
      const lastSeen = c.getCurrentUserLastSeen();
      return c.needsAttention(lastSeen);
    });
  }

  hasUnreadMessagesFilteredCasesInDay(date: Date): boolean {
    const casesInDate = this.filteredCasesByDate.get(getDateKey(date));
    if (casesInDate === undefined) {
      return false;
    }
    return casesInDate.some(c => this.unreadCommentsMap.get(c.id));
  }

  private hasNeedsAttention(status: CaseStatusEnum): boolean {
    const cases = this.items.filter(c => c.data.status === status);
    return cases.some(c => {
      const lastSeen = c.getCurrentUserLastSeen();
      return this.unreadCommentsMap.get(c.id) ?? c.needsAttention(lastSeen);
    });
  }

  hasNeedAttentionUpcoming(): boolean {
    return this.hasNeedsAttention(CaseStatusEnum.UPCOMING);
  }

  hasNeedAttentionCurrent(): boolean {
    return this.hasNeedsAttention(CaseStatusEnum.CURRENT);
  }

  hasCaseInStatus(status: CaseStatusEnum): boolean {
    return this.items.some(c => c.data.status === status);
  }

  hasCaseInDateCurrentUser(date: Date): boolean {
    const entry = this.filteredCasesByDate.get(getDateKey(date));
    if (entry === undefined) {
      return false;
    }

    return entry.some(c => isCaseOfUser(c, this.rootStore.userStore.loggedInUser.data.id));
  }

  get needFillHuddleMeta(): boolean {
    const hasOpenedCase = this.openedCase !== null;
    const hasProcedureId = hasOpenedCase && this.openedCase?.basicCase?.data.procedureId !== null;
    return (
      hasOpenedCase && !hasProcedureId && this.rootStore.userRoleInCase !== USER_CASE_ROLES.NONE
    );
  }

  getLocalStorageData(): Map<string, string> {
    const data = new Map<string, string>();
    for (const key of cacheKeys) {
      const val = localStorage.getItem(key);
      if (val !== null) {
        data.set(key, val);
      }
    }
    return data;
  }

  getUploadcareSignature = flow(function* (this: ICaseStore) {
    try {
      if (this.openedCase === null) {
        return '';
      }
      const signature = yield apiService.getUploadcareSignature(this.openedCase.basicCase.id);
      return signature.uploadcareSignature;
    } catch (error) {
      log(error);
    }
  });

  setRefreshForm(refreshForm: boolean): void {
    this.refreshForm = refreshForm;
  }

  refreshFieldValuesCountSum = flow(function* (this: ICaseStore) {
    if (this.openedCase === null) {
      (this as CaseStore).fieldValuesCountAll.clear();
      (this as CaseStore).fieldValuesCountAttendingAllProcs.clear();
      (this as CaseStore).fieldValuesCountAttendingCurProc.clear();
      return;
    }

    const procedureId = this.openedCase.basicCase.data.procedureId as string;
    const fvc: IFieldValuesCountSum = yield apiService.getFieldValuesCount(
      procedureId,
      this.openedCase.basicCase.data.attendingId,
      this.openedCase.basicCase.data.siteId
    );
    updateFvcMap(fvc.all, (this as CaseStore).fieldValuesCountAll);
    updateFvcMap(fvc.attending, (this as CaseStore).fieldValuesCountAttendingAllProcs);
    updateFvcMap(
      fvc.attending.filter(fvc => fvc.procedureId === procedureId),
      (this as CaseStore).fieldValuesCountAttendingCurProc
    );
  });

  getFieldValuesCount(fieldId: string): IGetFieldValuesCount {
    const all = this.fieldValuesCountAll.get(fieldId) ?? new Map();
    const attendingAllProcs = this.fieldValuesCountAttendingAllProcs.get(fieldId) ?? new Map();
    const attendingCurProc = this.fieldValuesCountAttendingCurProc.get(fieldId) ?? new Map();

    return {
      all,
      attendingAllProcs,
      attendingCurProc
    };
  }

  setUnreadCommentsMap(map: Map<string, number>): void {
    this.unreadCommentsMap = map;
  }

  setCurrentUserPrevLastSeen(lastSeen: Date | null): void {
    this.currentUserPrevLastSeen = lastSeen;
  }

  get isUserFollowedCaseInPast(): boolean {
    const isFollowed = this.items.some(c => {
      return c.data.caseFollowers.some(
        cf => cf.userId === this.rootStore.userStore.loggedInUser.data.id
      );
    });
    return isFollowed;
  }

  setCaseToShowUnfollowTooltip(caseId: string | null): void {
    this.caseToShowUnfollowTooltip = caseId;
  }
}

export default CaseStore;
