import React, { type MouseEvent } from 'react';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from 'app/components/buttons/Button';
import CloseIcon from 'app/assets/icons/Close_icon';
import './TooltipWrapper.scss';
import variables from 'app/utils/variables.module.scss';

interface IProps {
  comp: React.JSX.Element;
  tooltipContent: React.JSX.Element | string;
  open?: boolean;
  handleCloseTooltip?: () => void;
  isCloseOnClickAway?: boolean;
  placement?: TooltipProps['placement'];
  offset?: [number, number];
  name: string;
}

const TooltipWrapper = (props: IProps): React.JSX.Element => {
  const {
    name,
    comp,
    open,
    handleCloseTooltip,
    tooltipContent,
    placement,
    offset,
    isCloseOnClickAway
  } = props;

  return (
    <Tooltip
      className={`tooltip-wrapper-${name}`}
      placement={placement ?? 'top'}
      TransitionComponent={Zoom}
      title={
        <ClickAwayListener
          onClickAway={() => {
            if (!isCloseOnClickAway) {
              return;
            }
            if (handleCloseTooltip) {
              handleCloseTooltip();
            }
          }}
        >
          <div
            className="tooltip-contnet"
            onClick={e => {
              e.stopPropagation();
              if (!handleCloseTooltip) {
                return;
              }
              handleCloseTooltip();
            }}
          >
            {tooltipContent}
            {handleCloseTooltip && (
              <Button
                classNames="btn close-tooltip-button"
                startIcon={<CloseIcon height={10} stroke={variables.black} />}
                onClick={async (e: MouseEvent) => {
                  e.stopPropagation();
                  if (!handleCloseTooltip) {
                    return;
                  }
                  handleCloseTooltip();
                }}
              />
            )}
          </div>
        </ClickAwayListener>
      }
      PopperProps={{
        // fix placement top bug
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                // limit tooltip position to top of anchor
                fallbackPlacements: []
              }
            },
            {
              name: 'offset',
              options: {
                offset: offset ?? [0, -8]
              }
            }
          ]
        }
      }}
      arrow
      open={open ?? undefined}
    >
      {comp}
    </Tooltip>
  );
};

export default TooltipWrapper;
