import { gql } from '@apollo/client';
import { COMMENT_FULL_DATA } from './comment';

export const CASE_FULL_DATA = gql`
  fragment CaseFullData on cases {
    id
    display_id
    site_id
    room
    site {
      id
      name
      attending_only
      specialties
    }
    plan_feedbacks(order_by: { created_at: asc }) {
      user {
        nick_name
        id
        avatar
        first_name
        last_name
        roles {
          role
        }
      }
      case_id
      rating
      text
      id
    }
    procedure_id
    procedure_title
    created_at
    updated_at
    learned
    specialty_id
    specialty_title
    case_field_values {
      field_id
      values
      other_values
      created_at
      updated_at
    }
    attending_defaults
    attending_id
    resident_id
    comments(order_by: { created_at: asc }) {
      ...CommentFullData
    }
    comment_to_anesthesia
    comment_to_nursing
    state
    case_date
    is_add_on
    description
    procedure_data
    ontology_version
    index_in_day
    standard_procedure
    status
    is_ready
    intraop_text
    assistant_filled_brief
    case_followers {
      user_id
      user {
        id
        nick_name
        first_name
        last_name
        avatar
        roles {
          role
        }
      }
      last_seen
    }
    case_state_logs {
      from_state
      to_state
      created_by_id
      created_at
    }
    attending {
      id
      nick_name
      avatar
      first_name
      last_name
      roles {
        role
      }
    }
    resident {
      id
      nick_name
      avatar
      first_name
      last_name
      roles {
        role
      }
    }
    patient_initials
    mrn
  }
  ${COMMENT_FULL_DATA}
`;

export const CASE_BASIC_DATA = gql`
  fragment CaseBasicData on cases {
    id
    display_id
    site_id
    procedure_id
    procedure_title
    created_at
    updated_at
    learned
    specialty_id
    specialty_title
    attending_defaults
    attending_id
    resident_id
    comment_to_anesthesia
    comment_to_nursing
    state
    case_date
    is_add_on
    description
    ontology_version
    index_in_day
    standard_procedure
    status
    is_ready
    intraop_text
    assistant_filled_brief
    room
    case_followers {
      user_id
      last_seen
    }
    case_field_values(order_by: { created_at: desc }, limit: 1) {
      created_at
    }
    mrn
    patient_initials
  }
`;
