import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import { getLogoutClicked, setLogoutClicked } from '../../mobxStore/storage';
import AmplService from '../../services/amplService';

const LOGIN_PROMPT = 'login';

const UNLogin = (): React.JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const isLogoutClicked = getLogoutClicked();
    AmplService.sendEvent(AmplService.EVENTS.LOGIN_START);

    if (isLogoutClicked) {
      setLogoutClicked(false);
      void loginWithRedirect({
        authorizationParams: {
          prompt: LOGIN_PROMPT
        }
      });
      return;
    }

    void loginWithRedirect({});
  }, []);
  return <div style={{ backgroundColor: '#fff' }}></div>;
};

export default observer(UNLogin);
