import { SplitFactory } from '@splitsoftware/splitio-browserjs/full';
import FF_CONFIG from './ffServiceConfig';
import type { IClient } from '@splitsoftware/splitio-browserjs/types/splitio';

const FEATURE_FLAG_PUSH_NOTIFICATIONS = 'pushNotifications';

const sdkKey = FF_CONFIG.sdkKey;
if (sdkKey === '' || sdkKey === undefined) {
  throw new Error('FF_CONFIG.sdkKey is not defined');
}

const factory = SplitFactory({
  core: {
    authorizationKey: sdkKey,
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users
    key: 'key'
  }
});

class FFService {
  client: IClient | null = null;

  isInit = false;

  async init(userId: string): Promise<void> {
    this.client = factory.client(userId);

    await new Promise<void>(resolve => {
      const timer = setTimeout(() => {
        console.log('SDK_READY event not fired, timeout resolve');
        resolve();
      }, 2000);
      if (this.client === null) {
        throw new Error('client is null');
      }
      this.client.on(this.client.Event.SDK_READY, () => {
        this.isInit = true;
        clearTimeout(timer);
        resolve();
      });
    });
  }

  private isFlagOn(flagName: string): boolean {
    if (!this.isInit || this.client === null) {
      console.log('client not initialized');
      return false;
    }
    const is = this.client.getTreatment(flagName) === 'on';
    // console.log('isFlagOn', flagName, is);
    return is;
  }

  private getFlagConfig(flagName: string): SplitIO.TreatmentWithConfig | null {
    if (!this.isInit || this.client === null) {
      console.log('client not initialized');
      return null;
    }
    if (!this.isFlagOn(flagName)) {
      return null;
    }
    return this.client.getTreatmentWithConfig(flagName);
  }

  isFlagOnPushNotifications(): boolean {
    return (
      process.env.REACT_APP_ENABLE_PUSH === 'TRUE' || this.isFlagOn(FEATURE_FLAG_PUSH_NOTIFICATIONS)
    );
  }
}

const instance = new FFService();

export default instance;
